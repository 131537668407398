import React from "react";
import { Icon, Dropdown } from "semantic-ui-react";
import i18next from "i18next";
import { getConvertedItemDataForGA4, isPhotoUrlEndsWithNull, parseData, Productoptions, setIsAnuller } from "../../../../helpers/helpers";
import Colorbar from "./colorbar";
import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";
import AddToCart from "../../../addToCart/container/addToCart";
import StoreLocator from "../../../location/container/storeLocator";
import SizeGuide from "./SizeGuide";
import { PERSONALIZATION, PRODUCT_MAX_QUANTITY, STORE_DELIVERY, STOCK_LEFT } from "../../../../utils/constants/variables";
import RecommendedProducts from "../../../home/container/components/recommendedProducts";
// import Swiper from 'react-id-swiper';
import { BASE_API_GATEWAY } from "../../../../utils/constants/service";
import SocialBtn from "./socialMediaShareButtons";
import FullPageSlider from "../../../fullPageSlider/fullPageSlider";
import HeardIcon from "../../../products/container/heardIcon";
import Swiper from "react-id-swiper";
import DiscountDetail from "../../../common/product/discountDetail";
import { connect } from "react-redux";
import { getBanner, getBannerError } from "../../../home/redux/selectors";
import { TrackItemSelect } from "../../../../helpers/ga4Tracker";
import { bindActionCreators } from "redux";
import { ProductActionCreators } from "../../redux/actions";
import { getNotFoundProduct } from "../../../../layouts/main/header/redux/selectors";

interface Props {
    data: any,
    handleBasketToggle?: any,
    handleScrollToDescription?: any,
    recommendedProducts: any,
    handelResetLocationSearch: () => void,
    handleScrollTocompositeData: (event) => void,
    banner?: any,
    menuItem?: any,
    handleAddToCartEvent: any,
    selectColor?: any,
    notFoundProduct?: string
}

interface State {
    showColors: boolean,
    showDetails: boolean,
    selectedSize: string,
    selectedQuantity: any,
    price: any,
    productId: any,
    showStoreLocator: any,
    showSizeGuide: boolean,
    selectedElement: number,
    choosedSize: boolean,
    width: any,
    webInvertory: number | null,
    isFullPageSliderVisible: boolean;
    changedSize: any;
    activeSize: any;
    isChangedColor: boolean;
}

class ProductInfo extends React.Component<Props> {
    state: State = {
        showColors: true,
        showDetails: false,
        selectedSize: "",
        selectedQuantity: "",
        price: this.props.data.discountDetail ? this.props.data.listPriceAfterDiscount : this.props.data.startingPrice,
        productId: this.props.data.productId,
        showStoreLocator: false,
        showSizeGuide: false,
        selectedElement: 0,
        choosedSize: false,
        width: window.innerWidth,
        webInvertory: null,
        isFullPageSliderVisible: false,
        changedSize: null,
        activeSize: null,
        isChangedColor: false
    };

    componentWillMount(): void {
        window.scrollTo(0, 0)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const sizes = this.getProductSizes();
        if (sizes && sizes.length) {
            const size = sizes.find((size) => size.startingPrice !== undefined && size.webInventory > 0) || sizes[0];
            this.selectSize(size.size, size.productReference);
            this.selectQuantity("1")
        }
        window.addEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        const sizes = this.getProductSizes();
        if (prevProps.data && data && data.productId !== prevProps.data.productId && sizes && sizes.length) {
            this.selectSize(sizes[0].size, sizes[0].productReference);
            this.selectQuantity("1")
        }

        if (prevProps.data && data && prevProps.data.productId !== data.productId) {
            this.setState({ productId: this.props.data.productId })
        }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    getProductSizes = () => {
        try {
            const { data: { sizes = [] } = {} } = this.props;
            return STORE_DELIVERY === "1" ? sizes.sort((a, b) => a.productId - b.productId) : sizes.sort((a, b) => a.productId - b.productId).filter(size => size.webInventory > 0);
        } catch (error) {
            return [];
        }
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth })
    };

    toggleColors = () => {
        this.setState({ showColors: !this.state.showColors })
    };

    toggleDetails = () => {
        this.setState({ showDetails: !this.state.showDetails })
    };

    selectSize = (size: any, index) => {
        this.setState({
            selectedSize: index === this.state.selectedSize ? "" : index,
            choosedSize: index !== this.state.selectedSize,
            changedSize: typeof size === 'object' ? size : null
        })
    };

    trackItemOnSelect = (size: any) => {
        const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, this.state.selectedQuantity && this.state.selectedQuantity > 0 ? this.state.selectedQuantity : 1, size);
        const value = data.price;
        const valueT = value * data.quantity;
        TrackItemSelect(data, valueT)
        this.setState({
            activeSize: size
        })
    }

    trackItemOnQuantityChange = (quantity) => {
        const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, quantity, this.state.activeSize);
        const value = data.price;
        const valueT = value * +quantity;
        TrackItemSelect(data, valueT)
    }

    selectQuantity = (quantity) => {
        this.setState({ selectedQuantity: quantity, selectedElement: quantity })
    };

    toggleStoreLocator = () => {
        if (this.state.showStoreLocator) {
            const sizes = this.getProductSizes();
            if (sizes && sizes.length) {
                this.setState({
                    selectedSize: sizes[0].productReference || "",
                    choosedSize: !!sizes[0].productReference
                })
                this.selectQuantity("1")
            }
        }
        this.setState({ showStoreLocator: !this.state.showStoreLocator });
        // this.props.handelResetLocationSearch();
    };
    toggleSizeGuide = () => {
        this.setState({ showSizeGuide: !this.state.showSizeGuide });
    };
    handleAddedToCart = () => {
        const { data, menuItem } = this.props;
        const sizes = data.sizes ? data.sizes.filter(size => size.webInventory > 0) : [];
        this.props.handleAddToCartEvent(false, this.state.changedSize, this.state.selectedQuantity)

        this.setState({
            selectedQuantity: "1",
            selectedElement: 1,
            choosedSize: true,
            selectedSize: sizes && sizes.length ? sizes[0].productReference : data.sizes[0].productReference,
        });
        this.props.handleBasketToggle()
    };

    handleClickColor = (color?: any) => {
        const { data } = this.props;
        const sizes = data.sizes ? data.sizes.filter(size => size.webInventory > 0) : [];
        this.props.selectColor(true)
        if (sizes && sizes.length) {
            this.selectSize(sizes[0].size, sizes[0].productReference)
        }

        this.setState({
            selectedElement: 1,
            selectedQuantity: "1",
            choosedSize: true,
            isChangedColor: true
        });
    };

    StoreLocatorBtn = (text?) => {
        return (
            <div className="product-sell-content">
                <div className="addtocart">
                    <button
                        className="product-sell button-style hoverButtonBorderedStyle hovered store-icon"
                        onClick={this.toggleStoreLocator}
                    >
                        <span>{text ? text : STORE_DELIVERY === "1" ? i18next.t('product.product.15') : i18next.t('product.product.6')}</span>
                    </button>
                </div>
            </div>
        )
    }

    toggleSlider = () => {
        const { isFullPageSliderVisible } = this.state;
        this.setState({ isFullPageSliderVisible: !isFullPageSliderVisible });
    }

    getSelectedProductPrice = () => {
        const { data } = this.props;
        const { selectedSize } = this.state;
        const selectedProduct = Array.isArray(data.sizes) && data.sizes.length ? data.sizes.filter(elem => elem.productReference === selectedSize)[0] : [];

        return {
            startP: selectedSize === "" ? data.startingPrice : selectedProduct ? selectedProduct.startingPrice : "",
            discount: selectedSize === "" ? data.discountDetail : selectedProduct ? selectedProduct.discountDetail : "",
            newP: selectedSize === "" ? data.listPriceAfterDiscount : selectedProduct ? selectedProduct.listPriceAfterDiscount : ""
        }
    }

    render() {
        const { data, recommendedProducts, banner, notFoundProduct } = this.props;
        const params = {
            slidesPerView: 1,
            speed: 600,
            loop: data && data.length,
            autoplay: { delay: 5000 },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            wrapperClass: "swiper-wrapper",
            containerClass: "swiper-container",
            setWrapperSize: true,
            resizeReInit: true,
        }

        const {
            showColors,
            showDetails,
            selectedSize,
            selectedQuantity,
            price,
            productId,
            showStoreLocator,
            showSizeGuide,
            choosedSize,
            selectedElement,
            width,
            isFullPageSliderVisible
        } = this.state;
        const sizes = this.getProductSizes();
        const productPrice = this.getSelectedProductPrice();
        const selectedProduct = Array.isArray(data.sizes) && data.sizes.length ? data.sizes.filter(elem => elem.productReference === selectedSize)[0] : [];
        const webInvertory = selectedProduct && selectedProduct.webInventory;
        const checkDeliveyBySize = STORE_DELIVERY === "1" && !sizes.length;
        const checkDeliveyByWebInventory = STORE_DELIVERY !== "1" && !webInvertory;
        const options = [...Productoptions(STORE_DELIVERY === "1" ? PRODUCT_MAX_QUANTITY : webInvertory || undefined), { key: 0, value: "0", text: i18next.t("product.product.5") }];
        const cartData = {};

        let isMobile = width < 920;
        cartData[productId] = {
            name: data.name,
            canonicallUrl: data.canonicalUrl,
            canonicalMenu: data.canonicalMenu || "",
            size: selectedSize === "" ? {} : selectedProduct && Object.keys(selectedProduct).length ? selectedProduct : {},
            quantity: selectedQuantity,
            price: price,
            maxQuantity: PRODUCT_MAX_QUANTITY,
            photo: data.thumbnail || data.photo,
            pack: data.pack || [],
            oldPrice: data.discountDetail ? data.startingPrice : "",
            productCategory: data.productCategory,
            productCategoryId: data.productCategoryId,
            menuId: +this.props.menuItem.menuId,
            color: data.color || '',
            ...(data.brandName && { brandName: data.brandName }),
            ...(data.brandId && { brandId: data.brandId })
        };
        const params2 = {
            spaceBetween: 20,
            direction: 'horizontal',
            loop: true,
            loopFillGroupWithBlank: true,
            speed: 600,
            setWrapperSize: true,
            resizeReInit: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };
        return (
            <div className="product-container">
                <div className="product-info">
                    <div
                        className="zoomContent c-pointer"
                        onClick={this.toggleSlider}
                    >
                        <Icon
                            className="c-pointer zoom-icon"
                            name='zoom in'
                            size='large'
                        />
                        <HeardIcon product={data} menuItem={this.props.menuItem} />
                        <div className='mobile-product-slider'>
                            {
                                selectedProduct
                                    && selectedProduct.listPriceAfterDiscount
                                    && selectedProduct.listPriceAfterDiscount >= selectedProduct.startingPrice
                                    ? ""
                                    : (<DiscountDetail banner={banner} product={{ discountDetail: data.discountDetail, outlet: data.outlet, onSpecial: data.onSpecial }} isPhoto={true} />)
                            }
                            <Swiper {...params2}>
                                {data && data.photosX75 && data.photosX75.map((item, i) => {
                                    return (
                                        <div key={i} className="slide single-slider">
                                            <img src={`${BASE_API_GATEWAY}${item}`} alt="any" />
                                        </div>
                                    );
                                })}
                            </Swiper>
                        </div>

                        <div className='desktop-product-item'>
                            <Photo src={isPhotoUrlEndsWithNull(data && data.photo) || notFoundProduct}
                                discountDetail={data.discountDetail}
                                onSpecial={data.onSpecial}
                                thumbnail={isPhotoUrlEndsWithNull(data && data.thumbnail) || notFoundProduct}
                                alt={data && data.metaTitle}
                                zoom={false}
                                isMobile={isMobile}
                                outlet={data.outlet}
                                selectedProduct={selectedProduct}
                            />
                        </div>
                        {data && data.compositeCanonicalUrlForSingle ? <div className="discount-box">
                            <span
                                onClick={(event) => this.props.handleScrollTocompositeData(event)}>{i18next.t('product.parure.18')}</span>
                        </div> : null}
                    </div>
                    <div className="product-info--leftBox">
                        <div className="product-info-content product-info--leftBox--content">
                            <h1 className="product-info-title">{data.name}</h1>
                            <h4 className="product-category">{data.collection}</h4>
                            {/*<h2 className="product-info-text">{description.length > 200 ? `${description.substring(0, 200)}...` : description}*/}
                            {/*    <span*/}
                            {/*        onClick={() => this.props.handleScrollToDescription()}>{i18next.t('product.parure.11')}</span>*/}
                            {/*</h2>*/}
                            {data.colors && data.colors.length > 1 ?
                                <div className="product-color">
                                    <h2 className="product-color-title"
                                        onClick={this.toggleColors}>{i18next.t('product.product.2')}</h2>
                                    {showColors &&
                                        <Colorbar
                                            data={data.colors}
                                            backLink={`${this.props.data.canonicalMenu}/${this.props.data.canonicalUrl}`}
                                            handleClickColor={this.handleClickColor}
                                        />
                                    }
                                </div>
                                :
                                ""
                            }
                            <div>
                            </div>
                            {
                                data.inSale === false || !sizes.length ?
                                    <div className="product-size">
                                        <h4 className="product-size-title no-size">{i18next.t(`product.product.${!data.inSale ? 21 : 18}`)}</h4>
                                    </div>
                                    :
                                    <>
                                        <div className="product-size">
                                            <h4 className="product-size-title">{i18next.t('product.product.3')}</h4>
                                            <div className="product-size-item-content">
                                                <div className="product-size-item-content-wrapper">
                                                    {
                                                        sizes.map((item, i) =>
                                                        (item.startingPrice &&
                                                            <div key={i}>
                                                                <div
                                                                    className={`product-size-item ${selectedSize === item.productReference ? "active" : ""}`}
                                                                    onClick={
                                                                        () => {
                                                                            this.selectSize(item, item.productReference)
                                                                            this.setState({ webInvertory: item.webInventory })
                                                                            this.trackItemOnSelect(item)
                                                                        }
                                                                    }
                                                                >
                                                                    <p>{item.size}</p>
                                                                </div>
                                                                {item.altSize && <div className="AltSize"> {item.altSize}</div>}
                                                                {
                                                                    STOCK_LEFT === "1" && selectedSize === item.productReference && webInvertory !== null && webInvertory >= 1 && webInvertory < 5 && STORE_DELIVERY !== '1'
                                                                        ?
                                                                        <div
                                                                            className="product-item-count">{i18next.t('product.product.23', { count: webInvertory })}</div>
                                                                        :
                                                                        ""
                                                                }
                                                            </div>)
                                                        )
                                                    }
                                                </div>

                                                <div className="show-store-locator"
                                                    onClick={this.toggleSizeGuide}>{i18next.t('product.product.4')}</div>
                                            </div>

                                        </div>
                                        <div className="product-count">
                                            {checkDeliveyBySize || checkDeliveyByWebInventory ? (
                                                ""
                                            ) : (
                                                <h4 className="product-count-title">{i18next.t('product.product.12')}</h4>
                                            )}
                                            <div className='product-count-content'>
                                                {checkDeliveyBySize || checkDeliveyByWebInventory || !productPrice.startP ? (
                                                    ""
                                                ) : (
                                                    <Dropdown
                                                        className="mainSelect"
                                                        value={selectedQuantity}
                                                        options={options}
                                                        selection
                                                        onChange={(e, { value }) => {
                                                            this.selectQuantity(value)
                                                            this.trackItemOnQuantityChange(value)
                                                        }}
                                                        icon={<Icon name='angle down' />}
                                                    />
                                                )}
                                                <div className="product-price">
                                                    {!webInvertory ? "" : selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}
                                                    {Array.isArray(data.sizes) && data.sizes.length ? (
                                                        productPrice.startP && <Price
                                                            startP={productPrice.startP}
                                                            discount={productPrice.discount}
                                                            newP={productPrice.newP}
                                                            quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                                        />
                                                    ) : (
                                                        <Price
                                                            startP={data.startingPrice}
                                                            discount={data.discountDetail}
                                                            newP={data.listPriceAfterDiscount}
                                                            quantity={1}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }

                            <div className="product-sell-content two--btn">
                                {
                                    !checkDeliveyBySize && checkDeliveyByWebInventory && webInvertory !== undefined
                                        ?
                                        <div
                                            className="product-alert-title">{i18next.t('catalogue.product.7')} </div>
                                        :
                                        <>
                                            {
                                                !sizes.length ?
                                                    <div className="product-price">
                                                        {i18next.t('catalogue.product.3')}
                                                        {
                                                            Array.isArray(data.sizes) && data.sizes.length ?
                                                                <Price
                                                                    startP={productPrice.startP}
                                                                    discount={productPrice.discount}
                                                                    newP={productPrice.newP}
                                                                    quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                                                />
                                                                :
                                                                <Price
                                                                    startP={data.startingPrice}
                                                                    discount={data.discountDetail}
                                                                    newP={data.listPriceAfterDiscount}
                                                                    quantity={1}
                                                                />
                                                        }
                                                    </div>
                                                    :
                                                    <AddToCart
                                                        buttonText={i18next.t('product.product.13')}
                                                        data={cartData}
                                                        isEnable={Number(selectedElement) && choosedSize}
                                                        handleAddedToCard={this.handleAddedToCart}
                                                        toggleStoreLocator={this.toggleStoreLocator}
                                                        menuItem={this.props.menuItem}
                                                    />
                                            }
                                        </>

                                }
                                {!checkDeliveyBySize && this.StoreLocatorBtn(data.inSale === false && sizes.length ? i18next.t('product.product.6') : '')}
                            </div>
                            <div className="product-info-content-mobile">
                                <h4>
                                    {selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}
                                    {
                                        Array.isArray(data.sizes) && data.sizes.length ?
                                            <Price
                                                startP={productPrice.startP}
                                                discount={productPrice.discount}
                                                newP={productPrice.newP}
                                                quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                            />
                                            :
                                            ""
                                    }
                                </h4>
                                <div className="product-info-mobile-button">
                                    {!showDetails ?
                                        <button onClick={this.toggleDetails}>
                                            {i18next.t('product.product.16')}
                                        </button>
                                        :
                                        <>
                                            <AddToCart
                                                buttonText={i18next.t('product.product.16')}
                                                data={cartData}
                                                handleAddedToCard={this.handleAddedToCart}
                                                toggleStoreLocator={this.toggleStoreLocator}
                                                menuItem={this.props.menuItem}
                                            />
                                            <div className="product-popup-mobile open">
                                                <div className="product-popup-mobile-wrapper">
                                                    <Icon name="x" onClick={this.toggleDetails} />
                                                    <h4 className="product-popup-mobile-title">{i18next.t('product.product.3')}</h4>
                                                    <div
                                                        className="dflex flex-wrap align-items-center product-popup-mobile-sizes">
                                                        {sizes.map((item, i) => (
                                                            <div
                                                                key={i}
                                                                className={`product-size-item ${selectedSize === i ? "active" : ""}`}
                                                                onClick={() => {
                                                                    this.selectSize(item, i)
                                                                    this.trackItemOnSelect(item)
                                                                }}
                                                            >
                                                                {item.size}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <a href="#" rel="noopener">{i18next.t('product.product.4')}</a>

                                                    <h4 className="product-popup-mobile-title">{i18next.t('product.product.12')}</h4>
                                                    <Dropdown
                                                        className="mainSelect"
                                                        selection
                                                        value={selectedQuantity}
                                                        options={options}
                                                        onChange={(e, { value }) => {
                                                            this.selectQuantity(value)
                                                            this.trackItemOnQuantityChange(value)
                                                        }}
                                                        icon={<Icon name='angle down' />}
                                                    />

                                                    <div className="product-popup-mobile-price-content">
                                                        <h4>
                                                            {selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}
                                                            {
                                                                Array.isArray(data.sizes) && data.sizes.length ?
                                                                    <Price
                                                                        startP={productPrice.startP}
                                                                        discount={productPrice.discount}
                                                                        newP={productPrice.newP}
                                                                        quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                                                    />
                                                                    :
                                                                    ""
                                                            }
                                                        </h4>
                                                        {this.StoreLocatorBtn()}
                                                        {/* <div className="show-store-locator"
                                                             onClick={this.toggleStoreLocator}>{STORE_DELIVERY === "1" ? i18next.t('product.product.15') : i18next.t('product.product.6')}</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="product-info--leftBox--sliderContent">
                            {
                                PERSONALIZATION === "1" ? <RecommendedProducts recommendedProducts={recommendedProducts}
                                    sliderType="productinfo" /> : null
                            }
                            <SocialBtn urls={data && BASE_API_GATEWAY + data.photo} />
                        </div>
                    </div>
                </div>
                {showStoreLocator && (
                    <StoreLocator
                        from='deliveryPayment'
                        changeAnullar={setIsAnuller}
                        toggleStoreLocator={this.toggleStoreLocator}
                        product={STORE_DELIVERY === "1" ? data : {}}
                        selectedQuantity={selectedQuantity}
                        selectQuantity={this.selectQuantity}
                        sizes={data.sizes}
                        selectedSize={selectedSize}
                        selectSize={this.selectSize}
                        isPayment={false}
                        handleAddedToCart={this.handleAddedToCart}
                        cartData={cartData}
                        parentID={productId}
                        isHourVisible={false}
                    />
                )}
                {showSizeGuide &&
                    <SizeGuide toggleStoreLocator={this.toggleSizeGuide} />
                }
                <FullPageSlider
                    onClose={this.toggleSlider}
                    show={isFullPageSliderVisible}
                    data={(data && data.photosX75 && data.photosX75.length && data.photosX75) || [notFoundProduct]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    banner: getBanner(state),
    error: getBannerError(state),
    notFoundProduct: getNotFoundProduct(state)
});

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(ProductActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo)
